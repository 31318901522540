import '@/styles/global.css';
import type { AppProps } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import Head from 'next/head';
import { Footer } from '@/components/footer';
import { Navbar } from '@/modules/landing-page/navbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Tooltip } from 'react-tooltip';
import { GLOBAL_TOOLTIP_ID } from '@/utils/constants';
import { appWithTranslation } from 'next-i18next';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>Alphalitical</title>
      </Head>
      <Navbar />
      <main>
        <Component {...pageProps} />
      </main>
      <Footer />
      <Tooltip id={GLOBAL_TOOLTIP_ID} />
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp);
