import { button } from '@/components/Button/button';
import { cva, cx } from 'class-variance-authority';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './navbar.module.css';
import { useRouter } from 'next/router';

import { Collapse } from 'flowbite';
import type { CollapseOptions, CollapseInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { isBrowser } from 'react-device-detect';

const menuStyles = cva(['p-2 text-base leading-6'], {
  variants: {
    active: {
      true: !isBrowser ? 'text-gray-850' : 'text-gray-850 border-b-2 border-solid border-[#FF9500]',
      false: 'text-gray-500',
    },
  },
  defaultVariants: {
    active: false,
  },
});

const containerStyles = cva(['top-0 w-full z-20 font-sans transition-all'], {
  variants: {
    type: {
      landing: 'fixed',
      normal: 'bg-white py-8',
    },
    scrolled: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      type: 'landing',
      scrolled: true,
      className: 'bg-white py-2 ' + styles.scrolled,
    },
    {
      type: 'landing',
      scrolled: false,
      className: 'transparent py-8',
    },
  ],
  defaultVariants: {
    type: 'normal',
    scrolled: true,
  },
});

const TRANSPARENT_PATHNAME = [
  '/',
  '/careers/[slug]',
  '/privacy-policy',
  '/terms-and-condition',
  '/about-us',
  '/compare-plan',
];

export const Navbar = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState<'features' | 'why' | 'pricing' | 'home'>('home');
  const router = useRouter();
  const pathname = router.pathname;
  const shouldShowTransparent = TRANSPARENT_PATHNAME.includes(pathname);

  React.useEffect(() => {
    const handleScroll = (e: Event) => {
      const featureDiv = document.getElementById('features');
      const whyDiv = document.getElementById('why-alphalitical');
      const pricingDiv = document.getElementById('pricing');
      const _document: Document = e.target as Document;
      const scrollElement = _document.scrollingElement;
      const scrollTop = scrollElement?.scrollTop || 0;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (pricingDiv && scrollTop >= pricingDiv.offsetTop - 100) {
        setCurrentSection('pricing');
      } else if (whyDiv && scrollTop >= whyDiv.offsetTop - 100) {
        setCurrentSection('why');
      } else if (featureDiv && scrollTop >= featureDiv.offsetTop - 100) {
        setCurrentSection('features');
      } else {
        setCurrentSection('home');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  if (typeof document !== 'undefined') {
    const targetEl: HTMLElement | null = document.getElementById('targetEl');

    const triggerEl: HTMLElement | null = document.getElementById('triggerEl');

    const options: CollapseOptions = {
        onCollapse: () => {
            console.log('element has been collapsed');
        },
        onExpand: () => {
            console.log('element has been expanded');
        },
        onToggle: () => {
            console.log('element has been toggled');
        },
    };

    const instanceOptions: InstanceOptions = {
      id: 'targetEl',
      override: true
    };
    const collapse: CollapseInterface = new Collapse(
        targetEl,
        triggerEl,
        options,
        instanceOptions
    );

  }
  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <Link href="/">
        <Image src="/logo-color.svg" alt="Logo" width={208} height={40} className="w-[20.8rem] h-[4rem]" />
      </Link>
      <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {/* {
            isBrowser ?
            <Link
              href={process.env.NEXT_PUBLIC_LOGIN_URL || '#'}
              className={button({
                variant: 'secondary',
                size: 'md',
                className: 'h-10 flex items-center justify-center bg-transparent',
              })}
            >
              Sign In
            </Link>: null
          } */}
          <button id='triggerEl' data-collapse-toggle="targetEL" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="targetEL" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button>
      </div>
      <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="targetEL">
        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <Link
              className={menuStyles({
                active: pathname === '/' && currentSection === 'home',
              })}
              href="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className={menuStyles({
                active: pathname === '/' && currentSection === 'features',
              })}
              href="/#features"
            >
              Feature
            </Link>
          </li>
          <li>
            <Link
              className={menuStyles({
                active: pathname === '/' && currentSection === 'why',
              })}
              href="/#why-alphalitical"
            >
              Why Alphalitical
            </Link>
          </li>
          <li>
            <Link
              className={menuStyles({
                active: pathname === '/search' || pathname === '/search/detail',
              })}
              href="/search"
            >
              Search Trend
            </Link>
          </li>
          <li>
            <Link
              className={menuStyles({
                active: pathname === '/blog',
              })}
              href="/blog"
            >
              Blog
            </Link>
          </li>
          {/* {
            !isBrowser ?
            <li>
            <Link
              href={process.env.NEXT_PUBLIC_LOGIN_URL || '#'}
              className={button({
                variant: 'secondary',
                size: 'md',
                className: 'h-10 flex items-center justify-center bg-transparent mt-3',
              })}
            >
              Sign In
            </Link>
          </li>: null
          } */}
        </ul>
      </div>
      </div>
    </nav>
  );
};
