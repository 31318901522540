import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export const Footer = () => {
  return (
    <div>
      <BrowserView>
      <footer className="bg-gray-950 w-full pt-20 px-[10rem] pb-8 text-[#FBFCFE]">
          <div className="flex gap-3 items-center">
            <Image src="/logo.svg" alt="alphalitical logo" width={204} height={24} />
            <p className="text-base leading-snug pl-3 border-l border-[#FBFCFE]">
              Decoding data library into predictive analysis that create impactful business strategies.
            </p>
          </div>
          <address className="text-base leading-snug mt-6 not-italic">
            Wijaya Grand Centre Blok E No. 12B, Pulo, Kebayoran Baru, Jakarta Selatan, 12160
          </address>
          <div className="flex items-center justify-between mt-[5rem] gap-4">
            <ul className="flex items-center gap-8 text-base leading-snug font-medium flex-shrink-0">
              <li>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link href="/terms-and-condition">Terms & Condition</Link>
              </li>
              <li>
                <Link href="/about-us">About us</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact</Link>
              </li>
              {/* <li>
                <Link href="/careers">Careers</Link>
              </li> */}
            </ul>
            <ul className="flex items-center gap-4 flex-shrink-0">
              <li>
                <a
                  href="https://www.facebook.com/Alphalitical-100205812333727/?ref=pages_you_manage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src="/images/socmed/facebook.svg" alt="Facebook" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/AlphaliticalDev" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/twitter.svg" alt="Twitter" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/alphalitical/" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/instagram.svg" alt="Instagram" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/alphalitical/" target="_blank" rel="noreferrer">
                  <Image src="/images/socmed/linkedin.svg" alt="LinkedIn" width={24} height={24} />
                </a>
              </li>
            </ul>
          </div>
          <hr className="border-[#FBFCFE] border-t-[0.05rem] w-full mt-[5rem]" />

          <p className="text-base text-[#CDD2D9] leading-snug mt-10 text-center">© Copyright 2022 by Alphalitical.</p>
      </footer>
    </BrowserView>
    <MobileView>
      <footer className="bg-gray-950 w-full pt-20 px-[3rem] pb-8 text-[#FBFCFE]">
          <div className="flex gap-3 justify-center text-center">
            <Image src="/logo.svg" alt="alphalitical logo" width={204} height={24} />
          </div>
          <p className="text-base text-center leading-snug mt-6">
            Decoding data library into predictive analysis that create impactful business strategies.
          </p>
          <address className="text-base text-center leading-snug mt-6 not-italic">
            Wijaya Grand Centre Blok E No. 12B, Pulo, Kebayoran Baru, Jakarta Selatan, 12160
          </address>
          <div className="flex justify-between gap-4 mt-8">
            <Link href="/privacy-policy">Privacy Policy</Link>
            <Link href="/terms-and-condition">Terms & Condition</Link>
            <Link href="/about-us">About us</Link>
            <Link href="/contact-us">Contact</Link>
          </div>
          <div className="flex items-center justify-around mt-[5rem] gap-4">
            <a
              href="https://www.facebook.com/Alphalitical-100205812333727/?ref=pages_you_manage"
              target="_blank"
              rel="noreferrer"
            >
              <Image src="/images/socmed/facebook.svg" alt="Facebook" width={24} height={24} />
            </a>
            <a href="https://twitter.com/AlphaliticalDev" target="_blank" rel="noreferrer">
              <Image src="/images/socmed/twitter.svg" alt="Twitter" width={24} height={24} />
            </a>
            <a href="https://www.instagram.com/alphalitical/" target="_blank" rel="noreferrer">
              <Image src="/images/socmed/instagram.svg" alt="Instagram" width={24} height={24} />
            </a>
            <a href="https://www.linkedin.com/company/alphalitical/" target="_blank" rel="noreferrer">
              <Image src="/images/socmed/linkedin.svg" alt="LinkedIn" width={24} height={24} />
            </a>
          </div>
          <hr className="border-[#FBFCFE] border-t-[0.05rem] w-full mt-[5rem]" />

          <p className="text-base text-[#CDD2D9] leading-snug mt-10 text-center">© Copyright 2022 by Alphalitical.</p>
      </footer>
    </MobileView>
    </div>
  );
};
